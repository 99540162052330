<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="4">
        <BaseMonthPicker
          :value.sync="monthYear"
          :label="$t('forMonth')"
          :showMonthPicker.sync="showMonthPicker"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4">
      <v-col cols="12" lg="8">
        <v-card class="pt-6 pb-4">
          <SummaryBarChart
            :options="barChartOptions"
            :chartData="expenseSumByCategoryChartData"
          />
        </v-card>
      </v-col>
      <v-col colls="12" lg="4">
        <ExpenseOverview
          :sum="expenseAllSum"
          :limitSum="limitSum"
          :sum-by-user="expenseSumByUser"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="pr-md-2">
        <SumExpensesByCategory
          ref="expenseSumTable"
          :data="expenseSumByCategory"
        />
      </v-col>
      <v-col cols="12" lg="6" class="pl-md-2">
        <ExpensesByCategory ref="expenseTable" :data="expensesData" />
      </v-col>
    </v-row>
    <ExpenseForm
      v-if="showAdd"
      :editing="false"
      :showExpenseForm.sync="showAdd"
    />
    <div class="fab-container">
      <ScrollToButton
        v-if="$vuetify.breakpoint.mobile"
        class="mr-2"
        :scrollToElement="$refs.expenseTable"
        :icon="mdiTable"
        color="secondary"
      />
      <ScrollToButton
        v-if="$vuetify.breakpoint.mobile"
        class="mr-2"
        :scrollToElement="$refs.expenseSumTable"
        :icon="mdiSigma"
        color="secondary"
      />
      <v-btn
        color="primary"
        :large="!$vuetify.breakpoint.mobile"
        fab
        right
        @click="showAdd = true"
      >
        <v-icon>{{ mdiPlus }}</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mdiPlus, mdiSigma, mdiTable } from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';

import BaseMonthPicker from '@/components/inputs/BaseMonthPicker';
import ExpenseOverview from '@/components/expense/ExpenseOverview';
import SummaryBarChart from '@/components/charts/SummaryBarChart';
import SumExpensesByCategory from '@/components/tables/SumExpensesByCategory';
import ExpensesByCategory from '@/components/tables/ExpensesByCategory';
import ScrollToButton from '@/components/buttons/ScrollToButton';

import { categoriesData } from '@/mixins/categoriesMixin';
import monthPickerMixin from '@/mixins/monthPickerMixin';
import userMixin from '@/mixins/userMixin';
import expenseVewMixin from '@/mixins/expenseVewMixin';

export default {
  components: {
    BaseMonthPicker,
    ExpenseForm: () => import('@/components/forms/ExpenseForm'),
    ExpenseOverview,
    SummaryBarChart,
    SumExpensesByCategory,
    ExpensesByCategory,
    ScrollToButton,
  },

  mixins: [categoriesData, monthPickerMixin, userMixin, expenseVewMixin],

  data: () => ({
    mdiTable,
    mdiPlus,
    mdiSigma,
    showAdd: false,
  }),

  created() {
    if (!this.categoriesData.length) this.getCategoriesData();
    this.getLimits({
      date: this.expenseMonth,
    });
    this.getExpenses({ date: this.expenseMonth });
  },

  methods: {
    ...mapActions(['getExpenses', 'getLimits']),
  },

  computed: {
    ...mapState({
      expensesData: (state) => state.expenses.expenses,
    }),

    ...mapGetters([
      'expenseAllSum',
      'expenseSumByCategory',
      'expenseSumByUser',
      'expenseSumByCategoryChartData',
      'limitSum',
    ]),
  },

  watch: {
    monthYear() {
      if (this.expensesLoaded) {
        this.getLimits({
          date: this.expenseMonth,
        });
        this.getExpenses({ date: this.expenseMonth });
      }
    },
  },
};
</script>

<style scoped>
.fab-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
}
</style>
