<template>
  <v-card>
    <v-data-table
      :sort-by="['value']"
      :sort-desc="[true]"
      :items-per-page="100"
      :headers="headers"
      :items="data"
      item-key="category"
      v-model="selectedCategory"
      hide-default-footer
      single-select
      show-select
    >
      <template v-slot:item.value="{ value }">
        {{ value | currency }}
      </template>
      <template v-slot:item.limit="{ value }">
        {{ value.value | currency }}
      </template>
      <template v-slot:item.limitDiff="{ item }">
        <v-chip
          :color="getColor(item.limitDiff, item.value, item.limit.value)"
          dark
          >{{ item.limitDiff | currency }}</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          v-if="!isNaN(parseFloat(item.limit.value))"
          small
          @click="editLimit(item.limit)"
          >{{ mdiPencil }}</v-icon
        >
      </template>
    </v-data-table>

    <LimitForm
      v-if="showEdit"
      :showForm.sync="showEdit"
      :limitData="editLimitData"
    />
  </v-card>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import { limitMixin } from '@/mixins/limitMixin';

export default {
  props: {
    data: { type: Array, required: true },
  },

  components: {
    LimitForm: () => import('@/components/forms/LimitForm'),
  },

  mixins: [limitMixin],

  data: () => ({
    editLimitData: {},
    showEdit: false,
    mdiPencil,
  }),

  computed: {
    headers() {
      const staticHeaders = [
        {
          text: this.$t('category'),
          value: 'category',
        },
        {
          text: this.$t('sumExpenses'),
          value: 'value',
        },
        {
          text: this.$t('limit'),
          value: 'limit',
        },
        {
          text: this.$t('limitLeft'),
          value: 'limitDiff',
        },
      ];
      const someDataHasLimit = this.data.some((item) => item.limit.url);

      if (someDataHasLimit) {
        staticHeaders.push({
          text: this.$t('edit'),
          value: 'action',
          sortable: false,
        });
      }

      return staticHeaders;
    },

    selectedCategory: {
      get() {
        const expansesFilter = this.$store.state.menu.expansesFilter;

        return this.data.filter((item) => item.category === expansesFilter);
      },
      set(value) {
        if (value.length) {
          this.$store.commit('setExpansesFilter', value[0].category);
        } else {
          this.$store.commit('setExpansesFilter', '');
        }
      },
    },
  },

  methods: {
    emitClickedCategory(rowData) {
      this.$store.commit('setExpansesFilter', rowData.category);
    },

    editLimit(limit) {
      this.editLimitData = limit;
      this.showEdit = true;
    },
  },
};
</script>
