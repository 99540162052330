<template>
  <v-card>
    <v-card-title>
      {{ $t('allExpensesForMonth') }}
      <div class="flex-grow-1"></div>
      <v-text-field
        v-model="search"
        :label="$t('search')"
        :append-icon="mdiMagnify"
        clearable
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :sort-by="['date']"
      :sort-desc="[true]"
      :headers="headers"
      :items="data"
      :search="search"
      :items-per-page="20"
    >
      <template v-slot:item.date="{ value }">
        {{ value | hrDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editExpense(item)">{{
          mdiPencil
        }}</v-icon>
        <v-icon small @click="deleteExpense(item)">{{ mdiDelete }}</v-icon>
      </template>
      <template v-slot:item.value="{ value }">
        {{ value | currency }}
      </template>
    </v-data-table>
    <ExpenseForm
      v-if="showEdit"
      :editing="true"
      :editedExpense="editedExpense"
      :showExpenseForm.sync="showEdit"
    />
    <ExpenseDelete
      v-if="showDelete"
      :showDelete.sync="showDelete"
      :expenseToDelete="expenseToDelete"
    />
  </v-card>
</template>

<script>
import { mdiDelete, mdiMagnify, mdiPencil } from '@mdi/js';
import { mapState } from 'vuex';

export default {
  props: {
    data: Array,
    clickedCategory: String,
  },

  components: {
    ExpenseForm: () => import('@/components/forms/ExpenseForm'),
    ExpenseDelete: () => import('@/components/forms/ExpenseDelete'),
  },

  data: () => ({
    showEdit: false,
    showDelete: false,
    editedExpense: null,
    expenseToDelete: null,
    mdiDelete,
    mdiMagnify,
    mdiPencil,
  }),

  computed: {
    ...mapState({
      expansesFilter: (state) => state.menu.expansesFilter,

      headers() {
        return [
          {
            text: this.$t('date'),
            value: 'date',
          },
          {
            text: this.$t('user'),
            value: 'user_name',
          },
          {
            text: this.$t('category'),
            value: 'category_name',
          },
          {
            text: this.$t('expense'),
            value: 'value',
          },
          { text: this.$t('edit'), value: 'action', sortable: false },
        ];
      },
    }),

    search: {
      get() {
        return this.expansesFilter;
      },
      set(value) {
        this.$store.commit('setExpansesFilter', value);
      },
    },
  },

  methods: {
    deleteExpense(expense) {
      this.expenseToDelete = expense;
      this.showDelete = true;
    },
    editExpense(expense) {
      this.editedExpense = expense;
      this.showEdit = true;
    },
  },

  filters: {
    hrDate: function (value) {
      if (!value) return '';
      return new Date(value).toLocaleDateString('hr-HR');
    },
  },
};
</script>

