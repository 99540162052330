<template>
  <v-card class="d-flex flex-column justify-center" height="100%">
    <div class="align-self-center">
      <v-card-title class="text-xl-h4 text-lg-h5"
        >{{ $t('expenses') }}: {{ sum | currency }} {{ $t('currency') }}
      </v-card-title>
      <v-card-title
        v-for="sumUser in sumByUser"
        :key="sumUser.user"
        class="text-xl-h5 text-lg-h6"
        @click="emitClickedUser(sumUser)"
      >
        <v-btn
          :class="{
            'lighten-2': expansesFilter === sumUser.user,
            grey: expansesFilter === sumUser.user,
          }"
          outlined
          class="mr-4"
          >{{ sumUser.user }}</v-btn
        >
        {{ sumUser.sum | currency }} {{ $t('currency') }}
      </v-card-title>
      <v-card-title class="text-xl-h4 text-lg-h5"
        >{{ $t('limit') }}: {{ limitSum | currency }} {{ $t('currency') }}
      </v-card-title>
      <v-card-title>
        <v-chip
          :color="limitColor"
          class="text-xl-h4 text-lg-h5"
          x-large
          label
          dark
          >{{ $t('limitLeft') }}: {{ sumLimitLeft | currency }}
          {{ $t('currency') }}
        </v-chip>
      </v-card-title>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { limitMixin } from '@/mixins/limitMixin';

export default {
  props: {
    sum: Number,
    sumByUser: Array,
    limitSum: Number,
  },

  mixins: [limitMixin],

  computed: {
    ...mapState({
      expansesFilter: (state) => state.menu.expansesFilter,
    }),

    sumLimitLeft() {
      const sumDiff = (this.limitSum - this.sum).toFixed(2);
      return this.limitSum === 0 ? '-' : sumDiff;
    },
  },

  methods: {
    emitClickedUser(data) {
      this.$store.commit('setExpansesFilter', data.user);
    },
  },
};
</script>
