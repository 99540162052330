const limitMixin = {
  computed: {
    limitColor() {
      return this.getColor(this.sumLimitLeft, this.sum, this.limitSum);
    },
  },

  methods: {
    getColor(limitDiff, sumExpeses, limit = null) {
      const percentageLeft = limitDiff / sumExpeses;

      if (limitDiff < 0 && sumExpeses > 0) return 'red';
      if (limit === 0 || isNaN(limitDiff)) return 'blue-grey lighten-3';
      if (sumExpeses === 0 || percentageLeft > 0.1) return 'green';
      if (limit === sumExpeses || percentageLeft <= 0.1) return 'amber';
    },
  },
};

export { limitMixin };
