import { mapActions, mapState } from 'vuex';

export default {
  created() {
    if (!this.users.length) this.getUsers();
  },

  methods: {
    ...mapActions(['getUsers']),
  },

  computed: {
    ...mapState({
      users: (state) => state.users.users,
    }),
  },
};
