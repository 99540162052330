<template>
  <v-btn :color="color" fab small left @click="$vuetify.goTo(scrollToElement)">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: String,
    icon: String,
    scrollToElement: Object,
  },
};
</script>
